<template>
  <div class="main__wrapper">
    <div class="main">
      <div class="main-top-text">{{$t('h.navBar.toAccountInfo')}}</div>
      <el-form
        label-width="auto"
        label-position="left"
        class="main-form"
      >
        <el-form-item :label=" $t('h.accountInfo.accountName') + ':'">
          <el-input
            class="name-input"
            v-model="username"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item :label=" $t('h.accountInfo.logo') + ':'">
          <div>
            <el-upload
              class="upload-demo"
              :auto-upload="false"
              :on-change="uploadToCos"
              :show-file-list="false"
              action="">
              <el-button size="small" type="primary">upload</el-button>
              <!-- <div>只能上传jpg/png文件</div> -->
            </el-upload>
            <div class="logo-box" v-loading='loading'>
              <img class="logo" :src="imgUrl" alt="" />
              <div v-if="imgUrl!=='/DemoPDM/img/logo-black.3a6e87ac.png'" class="clear-btn el-icon-close" @click="imgUrl = '/DemoPDM/img/logo-black.3a6e87ac.png'"></div>
            </div>
            <el-button style="margin-top:50px" @click="uploadLogo(imgUrl)">{{ $t('h.accountInfo.confirm')  }}</el-button>
          </div>
          
        </el-form-item>
      </el-form>

    </div>
  </div>
</template>

<script>
import COS from "cos-js-sdk-v5";
export default {
  data() {
    return {
      username:localStorage.getItem("username"),
      imgUrl:'/DemoPDM/img/logo-black.3a6e87ac.png',
      cosBucket:'',
      cosRegion:'',
      task_id: "",
      loading:true,
    }
  },
  mounted(){
    this.getLogo()
    if (this.$store.state.user.country == 'China'){
        this.cosBucket='tme-chuangxin-1258344705'
        this.cosRegion='ap-guangzhou'
      }else{
        this.cosBucket='tme-chuangxin-hw-1258344705'
        this.cosRegion='ap-hongkong'
      }
  },
  methods: {
    async getLogo(){
      const result =  await this.$store.dispatch("getLogo", {
        token: localStorage.getItem("token"),
      });
      if(result.code ===2000 && result.logo_url){
        this.imgUrl=result.logo_url + "?" + Math.random()
      }
      this.loading = false
    },
    async uploadLogo(logo_url){
      const result =  await this.$store.dispatch("uploadLogo", {
        token: localStorage.getItem("token"),
        logo_url
      });
      if(result.code ===2000){
        this.imgUrl=''
        this.imgUrl=logo_url + "?" + Math.random()
        this.$message({
          message: 'Success!',
          type: "success",
          offset: 120,
        });
      }
    },
    async uploadToCos(file){
      this.loading = true
      const d = await this.$store.dispatch("getCosInfo", {
        token: localStorage.getItem("token"),
        is_logo:true
      });
      if (d.success) {
        this.cosInfo = d.result;
        const cosInfo = this.cosInfo;
        this.task_id = this.cosInfo.taskid;
        // COS实例
        const cos = new COS({
          SecretId: cosInfo && cosInfo.cos_secret_id,
          SecretKey: cosInfo && cosInfo.cos_secret_key,
          SecurityToken: cosInfo.cos_token,
        });
        // 汇总文件
        const fileList = [];
          fileList.push({
            Bucket:
              this.cosBucket /* 填入您自己的存储桶，必须字段 */,
            Region:
              this.cosRegion /* 存储桶所在地域，例如ap-beijing，必须字段 */,
            Key: `${cosInfo.key}${cosInfo.userid}.png` /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */,
            Body: file.raw,
          });
        // 批量上传
        const self = this;
        cos.uploadFiles(
          {
            files: fileList,
            SliceSize: 1024 * 1024 * 10 /* 设置大于10MB采用分块上传 */,
            onFileFinish: (err, data, options) => {
              const arr = options.Key.split('/')
              const fileName = arr[arr.length - 1]
              if (err) {
                setTimeout(() => {
                  self.$message({
                    message: fileName + self.$t('h.task.uploadFailMsg'),
                    type: "error",
                    offset: 120,
                  });
                }, 100)
              } else {
                console.log(fileName + ' ' + self.$t('h.task.uploadSuccess'));
                cos.getObjectUrl({
                  Bucket: this.cosBucket, /* 填入您自己的存储桶，必须字段 */
                  Region: this.cosRegion, /* 存储桶所在地域，例如 ap-beijing，必须字段 */
                  Key: `${cosInfo.key}${cosInfo.userid}.png` /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */,
                  Sign: false, /* 获取带签名的对象 URL */
                  Expires: 36000000000,
                },
                function (err, data) {
                  if (err) return console.log(err);
                  /* url为对象访问 url */
                  console.log(data)
                  self.imgUrl =data.Url+ "?" + Math.random()
                  self.loading = false
                });
              }
            },

          },
        );
        
      } else {
        this.$message({
          message: d.msg,
          type: "error"
        })
      }
    },
    
  }
}
</script>
<style lang="stylus" scoped>
  .main__wrapper{
  height: 100%;
  .main{
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}

.main-top-text {
  margin: 10px 0 0 0;
  font-size: 18px;
  font-weight: 600;
  color: #1a1a1a;
}
.main-form{
  flex: 1;
  width: 100%;
  margin-top:20px;
}
.name-input{
  width:30%;
}
.logo-box{
  border:  1px solid rgba(187, 187, 187, 1);
  width 200px;
  margin-top: 20px;
  padding: 15px 15px 10px;
  display: flex;
  .logo{
    width: 200px;
  }
  .clear-btn{
    cursor: pointer;

  }
}

</style>